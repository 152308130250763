.react-mapbox-ac-menu {
  width: auto;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-top: -1.3rem;
}

.react-mapbox-ac-input {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-suggestion {
  font-size: 14px;
  cursor: pointer;
  padding: .5rem 1.5rem;
}

.react-mapbox-ac-suggestion:hover {
  background-color: #58a;
}
